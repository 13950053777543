/*
	~ Contact Form Component
*/

import moment from 'moment';
import Form from '@atoms/Form/_form';
import Input from '@atoms/Form/_input';
import includeClasses from 'classnames';
import SubmitButton from '@atoms/_button';

import { useState } from 'react';
import {
	CircularProgress, Grid, Theme 
} from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { CSSObject } from '@emotion/react';
import { getClassesType } from '@interfaces/tssReact';
import { sendOrderToEmail } from '../../services/email';
import { validEmailRegex, validPhoneNumberRegex } from '@settings/validation';

import {
	Buttons,
	ButtonSizes,
	ButtonColors,
	ButtonVariants,
} from '@assets/styles/muiTypes/button';


enum ContactFormKeys {
	firstName = 'firstName',
	lastName = 'lastName',
	email = 'email',
	message = 'message',
	phone = 'phone',
}

interface iContactForm {
	[ContactFormKeys.firstName]: string,
	[ContactFormKeys.lastName]: string,
	[ContactFormKeys.email]: string,
	[ContactFormKeys.message]: string,
	[ContactFormKeys.phone]: string,
}


const Styles = (theme: Theme) => ({
	contactForm: {
		width: '75%',
	} as CSSObject,
});

export type stylesType = ReturnType<typeof Styles>;



interface iContactFormProps extends getClassesType<stylesType> {
	formClasses?: string,
}

const ContactForm = (props: iContactFormProps) => {
	const { formClasses = '' } = props;
	// const navigate = useNavigate();
	// const [, set] = useState(0);
	const classes = withStyles.getClasses<stylesType>(props);
	const [isSendingEmail, setIsSendingEmail] = useState(false);
	const [formKey, setFormKey] = useState(moment().toISOString());

	const handleFormSubmission = async (data: iContactForm) => {
		const {
			email,
			phone,
			message,
			lastName,
			firstName,
		} = data;

		setIsSendingEmail(true);

		const timeOfContactEntry = moment().format('MMMM Do/yy, h:mm a');

		try {
			// Send to owner
			const ownerResponse = await sendOrderToEmail(
				'operations@seacanman.ca',
				`seacanman.ca Customer Contact Form - ${timeOfContactEntry}`,
				`
A Contact form was filled out. Here is what was entered:
Email - ${email}
LastName - ${lastName}
FirstName - ${firstName}
Phone - ${phone}
Message - ${message}
				`
			);

			if (ownerResponse) {
				// eslint-disable-next-line
				alert('Successfully sent! Thank you for your inquiry and you can expect to hear back from us in 24 - 48 hours.');
				setFormKey(moment().toISOString());
			}

		} catch(error: any) {

		}

		setIsSendingEmail(false);
	};


	return (
		<Form<iContactForm> 
			key={`form_${formKey}`}
			onSubmit={handleFormSubmission}
			formClassOverride={
				includeClasses({
					[classes.contactForm]: true,
					[formClasses]: !!formClasses,
				})
			}>

			<Grid container>
				<Grid item xs={12} sm={6} paddingRight={4}>
					<Input
						label={'First Name'}
						helperText={'Your first name'}
						fieldName={ContactFormKeys.firstName}
						validation={{
							required: '*Required',
						}} />
				</Grid>
				<Grid item xs={12} sm={6}>
					<Input
						label={'Last Name'}
						helperText={'Your last name'}
						fieldName={ContactFormKeys.lastName}
						validation={{
							required: '*Required',
						}} />
				</Grid>
			</Grid>

			<Grid container>
				<Grid item xs={12} sm={6} paddingRight={4}>
					<Input
						label={'Email'}
						helperText={'Your email'}
						fieldName={ContactFormKeys.email}
						validation={{
							minLength: 3,
							required: '*Required',
							pattern: {
								value: validEmailRegex,
								message: 'Must be a valid email address',
							},
						}} />
				</Grid>
				<Grid item xs={12} sm={6}>
					<Input
						label={'Phone'}
						helperText={`Your phone number`}
						fieldName={ContactFormKeys.phone}
						validation={{
							required: '*Required',
							pattern: {
								value: validPhoneNumberRegex,
								message: 'Must be a valid phone number',
							},
						}} />
				</Grid>
			</Grid>
				
			<Input
				label={'Message'}
				helperText={`Let's start a conversation`}
				fieldName={ContactFormKeys.message}
				validation={{
					required: '*Required',
				}} />

			<SubmitButton
				type={Buttons.submit}
				size={ButtonSizes.large}
				color={ButtonColors.primary}
				variant={ButtonVariants.contained}>
			
				{ isSendingEmail ? (<CircularProgress size={26} color='primary' />) : 'Send!'}
			</SubmitButton>
		</Form>
	);
};


export default withStyles(ContactForm, Styles);