/*
	~ Buy Container Stepper Component
*/

import Button from '@atoms/_button';
import Form from '@atoms/Form/_form';
import Input from '@atoms/Form/_input';
import SelectableTile from '@atoms/_selectableTile';
import redHighImage from '@images/used_red_can.png';
import AutoCompletePlaces from '@atoms/_autoComplete';
import SelectableTileGroup from '@molecules/_selectableTileGroup';
import blueHighImage from '@images/40ft_can_blue_transparent.png';
import redShortImage from '@images/40ft_can_red_transparent.png';
import blueShortImage from '@images/20ft_can_blue_transparent.png';

import moment, { Moment } from 'moment';

import { analytics } from '../';
import { withStyles } from 'tss-react/mui';
import { CSSObject } from '@emotion/react';
import { logEvent } from 'firebase/analytics';
import { useTheme } from '@mui/material/styles';
import { sendOrderToEmail } from '../services/email';
import { DateTimePicker } from '@mui/x-date-pickers';
import { getClassesType } from '@interfaces/tssReact';
import { Containers, ContainerTypes } from '@interfaces/containers';

import {
	useState,
	useEffect,
	SyntheticEvent,
	RefObject,
	useRef,
} from 'react';

import {
	Grid,
	Theme,
	useMediaQuery,
} from '@mui/material';

import {
	Map, 
	useMap,
	MapMouseEvent, 
	useMapsLibrary,
	MapCameraChangedEvent,
} from '@vis.gl/react-google-maps';

import {
	ButtonSizes,
	ButtonColors, 
	ButtonVariants,
} from '@assets/styles/muiTypes/button';

import {
	Step,
	Stepper,
	StepLabel,
	Typography,
	StepContent,
	CircularProgress,
	
} from '@mui/material';
import { validEmailRegex } from '@settings/validation';


const steps = [
	'Choose your Container',
	'Choose your destination',
	'Choose your ideal delivery time',
	'Your Contact Info',
];

interface MapLocation {
	lat: number,
	lng: number,
}

interface MapLocationStack {
	[key: string]: MapLocation,
}

interface iHoursAndMinutes {
	mins: number,
	hours: number,
}

enum CustomerFormKeys {
	name = 'name',
	email = 'email',
	phone = 'phone',
}

interface iCustomerForm {
	[CustomerFormKeys.name]: string,
	[CustomerFormKeys.email]: string,
	[CustomerFormKeys.phone]: string,
}

const mapCenterConfig: MapLocationStack = {
	halifax: {
		lat: 44.651070,
		lng: -63.582687
	},
	// Wyse Road Dartmouth
	headquarters: {
		lat: 44.67210089159447,
		lng: -63.58128240039608,
	},
};

// Costs
const DOLLARS_PER_HOUR_20 = 160;
const DOLLARS_PER_HOUR_40 = 215;

const twenty_foot_new = 5500;
const twenty_foot_used = 4300;
const fourty_foot_new = 6700;
const fourty_foot_used = 4700;


const Styles = (theme: Theme) => ({
	container: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		justifyContent: 'flex-start',
	} as CSSObject,
	stepper: {
		width: '55%',

		[theme.breakpoints.down(theme.breakpoints.values.lg)]: {
			width: '92.5%',
		},
	} as CSSObject,
	stepTitle: {
		width: '100%',
		marginTop: 20,
		marginBottom: 40,
		textAlign: 'center',
	} as CSSObject,
	stepLabel: {} as CSSObject,
	stepIcon: {
		width: '1.43em',
		height: '1.43em',
		fontSize: '2rem',
	} as CSSObject,
	
	form: {
		width: '100%',
	} as CSSObject,

	chooseContainer: {
		width: '100%',
		paddingTop: '5vh',
		paddingBottom: '5vh',
	} as CSSObject,
	locationStepContainer: {} as CSSObject,
	selectableContainerImage: {
		width: '15vw',
		height: '15vw',
	} as CSSObject,
	actionButtons: {
		marginTop: 20,
		marginBottom: 20,
	} as CSSObject,
	deliveryTimeContainer: {
		display: 'flex',
		flexDirection: 'column',
	} as CSSObject,
	selectedContainer: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'row',
		justifyContent: 'space-between',
		
		[theme.breakpoints.down(theme.breakpoints.values.md)]: {
			alignItems: 'flex-start',
			flexDirection: 'column',
		},
	} as CSSObject,
	containerTile: {
		position: 'relative',
	} as CSSObject,
	containerTileOutOfStockBackground: {
		width: '100%',
		height: '100%',
		position: 'absolute',
		backgroundColor: 'rgba(0,0,0,0.45)',
		top: 0, left: 0, right: 0, bottom: 0,

	} as CSSObject,
	containerTileBanner: {
		left: 0,
		bottom: 0,
		zIndex: 9999,
		width: '100%',
		padding: `10px 0`,
		position: 'absolute',
		backgroundColor: theme.palette.secondary.dark,
	} as CSSObject,
	outOfStockLabel: {
		fontWeight: 900,
		color: '#ffffff',
		fontSize: '1.55em',
		textAlign: 'center',
	} as CSSObject,
	containerCountWrapper: {
		marginTop: 75,
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		
		[theme.breakpoints.down(theme.breakpoints.values.md)]: {
			width: '100%',
			flexDirection: 'column',
			alignItems: 'flex-start',
		},
	} as CSSObject,
	containerQuantity: {
		width: '65%',
		
		[theme.breakpoints.down(theme.breakpoints.values.md)]: {
			width: '100%',
		},
	} as CSSObject,
	orderReviewContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		justifyContent: 'flex-start',
	} as CSSObject,
	orderReviewStepCustomerInfo: {
		width: '100%',
		display: 'flex',
		marginBottom: 45,
		alignItems: 'center',
		flexDirection: 'row',
		justifyContent: 'space-between',
		
		[theme.breakpoints.down(theme.breakpoints.values.md)]: {
			flexDirection: 'column',
		},
	} as CSSObject,
	customerName: {
		width: '45%',
		marginRight: 45,
		
		[theme.breakpoints.down(theme.breakpoints.values.md)]: {
			width: '100%',
			marginRight: 0,
		},
	} as CSSObject,
	customerEmail: {
		width: '45%',

		[theme.breakpoints.down(theme.breakpoints.values.md)]: {
			width: '100%',
		},
	} as CSSObject,
	orderTitle: {
		marginTop: 35, 
		textDecoration: 'underline',
	} as CSSObject,
	orderList: {
		listStyle: 'none',

		[theme.breakpoints.down(theme.breakpoints.values.md)]: {
			paddingLeft: 0,
		},
	} as CSSObject,
	orderListItem: {
		fontSize: 18,
		padding: '5px 10px',
	} as CSSObject,
	orderItem: {
		color: theme.palette.primary.main,
	} as CSSObject,
	totalCost: {
		color: theme.palette.error.main,
	} as CSSObject,
	finalReviewContainerImage: {
		width: '90%',
		height: 'auto',
		maxHeight: 275,
	} as CSSObject,
});

export type stylesType = ReturnType<typeof Styles>;


interface iBuyContainerStepperProps extends getClassesType<stylesType> {
	onOrder?: () => void,
}

const BuyContainerStepper = (props: iBuyContainerStepperProps) => {
	// State Management
	const [activeStep, setActiveStep] = useState(0);
	const [timeToTravel, setTimeToTravel] = useState(0);
	const [customerName, setCustomerName] = useState('');
	const [customerEmail, setCustomerEmail] = useState('');
	const [customerPhone, setCustomerPhone] = useState('');
	const [isSendingOrder, setIsSendingOrder] = useState(false);
	const [containerQuantity, setContainerQuantity] = useState(1);
	const [calculatingCosts, setCalculatingCosts] = useState(false);
	const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
	const [deliveryTime, setDeliveryTime] = useState<Moment | null>(null);
	const [coordinates, setCoordinates] = useState(mapCenterConfig.headquarters);
	const [destinationAddress, setDestinationAddress] = useState<string | null>(null);
	const [selectedTileKey, setselectedTileKey] = useState(null as ContainerTypes | null);
	const [directionsService, setDirectionsService] = useState<google.maps.DirectionsService>();
	const [selectedCoordinates, setSelectedCoordinates] = useState(mapCenterConfig.headquarters);
	const [directionsRenderer, setDirectionsRenderer] = useState<google.maps.DirectionsRenderer>();

	// Consts
	const map = useMap();
	const theme = useTheme();
	const GoogleRoutesLibrary = useMapsLibrary('routes');
	const classes = withStyles.getClasses<stylesType>(props);
	const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.lg));

	// Refs
	const locationStepRef = useRef<HTMLDivElement>(null);
	const orderReviewStepRef = useRef<HTMLDivElement>(null);
	const deliveryTimeStepRef = useRef<HTMLDivElement>(null);
	const chooseContainerStepRef = useRef<HTMLDivElement>(null);

	const { onOrder } = props;


	// Use directions service
	useEffect(() => {
		setCalculatingCosts(true);
		calculateTimeToTravel();

		if (!directionsService || !directionsRenderer) return;

		directionsService
			.route({
				provideRouteAlternatives: false,
				destination: selectedCoordinates,
				origin: mapCenterConfig.headquarters,
				travelMode: google.maps.TravelMode.DRIVING,
			})
			.then(response => {
				directionsRenderer.setDirections(response);
			});
		
	}, [selectedCoordinates]);


	useEffect(() => {
		if (!GoogleRoutesLibrary || !map) return;

		setDirectionsService(new GoogleRoutesLibrary.DirectionsService());
		setDirectionsRenderer(new GoogleRoutesLibrary.DirectionsRenderer({
			map
		}));
	}, [GoogleRoutesLibrary, map]);

	useEffect(() => {
		console.log(`isMobile: `, isMobile);
		if (isMobile) {
			const ref = getRefByStep();
			console.log(`ref: `, ref);

			if (ref) {
				scrollToSection(ref);
			}
		}
	}, [activeStep]);


	const handleAdvanceButtonOnClick = () => {
		setActiveStep(activeStep + 1);
	};

	
	const handlePreviousStepButtonClick = () => {
		setActiveStep(activeStep - 1);
	};


	const getRefByStep = () => {
		console.log(`activeStep: `, activeStep);
		switch(activeStep) {
			case 1:
				return chooseContainerStepRef;
			case 2:
				return locationStepRef;
			case 3:
				return deliveryTimeStepRef;
			case 4:
				return orderReviewStepRef;
			default:
				return null;
		}
	};


	const scrollToSection = (ref: RefObject<HTMLDivElement>) => {
		const offset = ref?.current?.offsetTop;
		console.log(`offset: `, offset);
		if (offset !== undefined) {
			window.scrollTo({
				top: offset - 155,
				behavior: 'auto',
			});
		}
	};
	
	
	const handleCustomerNameChange = (event: SyntheticEvent<any>) => {
		if (event.target) {
			const { target } = event;

			const value = (target as HTMLInputElement).value;
			setCustomerName(value);
		}
	};
	
	
	const handleCustomerEmailChange = (event: SyntheticEvent<any>) => {
		if (event.target) {
			const { target } = event;

			const value = (target as HTMLInputElement).value;
			setCustomerEmail(value);
		}
	};
	
	const handleCustomerPhoneChange = (event: SyntheticEvent<any>) => {
		if (event.target) {
			const { target } = event;

			const value = (target as HTMLInputElement).value;
			setCustomerPhone(value);
		}
	};


	const handleDeliveryTimeOnChange = (newTime: Moment | null) => {
		if (newTime) {
			setDeliveryTime(newTime);
		}
	};


	const handleContainerSelectionOnChange = (selectedTile: ContainerTypes) => {
		if (selectedTile === selectedTileKey) {
			setselectedTileKey(null);

		} else {
			setselectedTileKey(selectedTile);
		}
	};


	const handleMapOnClick = (event: MapMouseEvent) => {
		if (event?.detail) {
			const { detail } = event;
			const { latLng } = detail;
			
			if (latLng?.lat && latLng?.lng) {
				setSelectedCoordinates(latLng);
			}
		}
	};


	const completeOrder = async (data: iCustomerForm) => {
		if (data) {
			setIsSendingOrder(true);
	
			const timeOfOrder = moment().format('MMMM Do/yy, h:mm a');
	
			try {
				// Send to owner
				const ownerResponse = await sendOrderToEmail(
					'operations@seacanman.ca',
					`seacanman.ca Customer Order - ${timeOfOrder}`,
					`
	Customer Order, at ${timeOfOrder}:
	Customer Name: ${customerName}
	Customer Email: ${customerEmail}
	Customer Phone: ${customerPhone}
	Number of containers: ${containerQuantity}
	Type of container: ${ getContainerNameByType(selectedTileKey) }
	Requested Time of Delivery: ${ deliveryTime?.format('ddd MMM Do, YYYY - h:mma') }
	Estimated Length of Time for Delivery:${  getDeliveryTimePretty() }
	Destination Address: ${ destinationAddress }
					`
				);
				
				/* eslint-disable */
				// Send to customer
				const customerResponse = await sendOrderToEmail(
					customerEmail,
					`Your seacanman.ca Shipping Container Order, ${moment().format('MMMM Do/yy, h:mm a')}`,
					`
	Hello ${customerName},
	
	Thank you for submitting an order at seacanman.ca. We appreciate the opportunity to earn your business!
	
	Our team will reach out to you within 24 to 48 hours to ensure we have the correct destination address, final cost and date of delivery.
	
	Here is a summary of your order...
	
	Your Order:
	Customer Name: ${customerName}
	Customer Phone: ${customerPhone}
	Number of Containers: ${containerQuantity}
	Type of Container: ${ getContainerNameByType(selectedTileKey) }
	Requested Time of Delivery: ${ deliveryTime?.format('ddd MMM Do, YYYY - h:mma') }
	Destination Address: ${ destinationAddress }
	
	Total costs: $${
		formatStringMoneyWithCommas(
			calculateTotalCosts(
				calculateDeliveryCosts(selectedTileKey, containerQuantity),
				selectedTileKey,
				containerQuantity
			)
		)}
	
	Delivery confirmation will be sent upon payment. Payment is accepted by certified cheque to Atlantic SeaCan Man or by e-transfer to james@seacanman.ca.
	
	Please reach out to operations@seacanman.ca with any questions you may have.
	
	Thanks for shopping local!
	Have a great day,
	
	The Seacanman.ca Team
					`
				);
				/* eslint-enable */
	
				if (ownerResponse && Object.hasOwn(ownerResponse, 'success') && ownerResponse.success === true && 
						customerResponse && Object.hasOwn(customerResponse, 'success') && 
							customerResponse.success === true) {
	
					// eslint-disable-next-line
					alert('Successfully sent an order to our processing department. We will be in touch within 24 - 48 hours to confirm delivery address, time and total cost');
					
					// Reset whole form!
					setDestinationAddress(null);
					setCoordinates(mapCenterConfig.headquarters);
					setSelectedCoordinates(mapCenterConfig.headquarters);
	
					if (onOrder) {
						onOrder();
					}
				}
	
				logEvent(
					analytics,
					'ordered',
					{
						'ordered_a_container': {
							name: customerName,
							email: customerEmail,
							number_of_containers: containerQuantity,
							destination_address: destinationAddress,
							container_type: getContainerNameByType(selectedTileKey),
							estimated_length_of_time_for_delivery: getDeliveryTimePretty(),
							requested_time_of_delivery: deliveryTime?.format('ddd MMM Do, YYYY - h:mma'),
						},
					}
				);
		
			} catch (error) {
				//
			}
	
			setIsSendingOrder(false);

		}
	};


	const handleContainerQuantityChange = (event: SyntheticEvent<any>) => {
		if (event.target) {
			const { target } = event;

			if ((target as HTMLInputElement).valueAsNumber) {
				const quantity = (target as HTMLInputElement).valueAsNumber;
				
				if (quantity >= 0) {
					setContainerQuantity(quantity);

				} else {
					setContainerQuantity(0);
				}
			} else {
				setContainerQuantity(0);
			}
		}
	};


	const checkOrderButtonDisabledState = () => {
		let isDisabled = false;

		if (containerQuantity <= 0 || 
			selectedTileKey === null || 
			customerName === '' || 
			customerEmail === '' || 
			deliveryTime === null || 
			destinationAddress === null ||
			selectedCoordinates === null) {

			isDisabled = true;
		}

		return isDisabled;
	};


	const calculateDeliveryCosts = (type: ContainerTypes | null, count = 0) => {
		let cost = 0;

		if (type && timeToTravel > 0 && count > 0) {
			const {
				mins, 
				hours,
			} = calculateHoursAndMinutesOfTime();

			let costPerHour = 0;
			if (type === Containers.size20StandardNew || type === Containers.size20StandardUsed) {
				costPerHour = DOLLARS_PER_HOUR_20;
			} else if (type === Containers.size40HighNew || type === Containers.size40HighUsed) {
				costPerHour = DOLLARS_PER_HOUR_40;
			}

			// Hours * container type cost + 1 hours setup/unloading fees
			const costPerUnitPerHour = ((hours + 1) * costPerHour) * count;

			if (mins > 0) {
				// Prorated hourly cost
				cost = Math.ceil(costPerUnitPerHour + (costPerHour * (mins / 60)));
			} else {
				// No minutes to prorate
				cost = Math.ceil(costPerUnitPerHour);
			}

			// Calculations up to this point are for a 1 way delivery fee
			// Now needs to be doubled for return trip
			cost *= 2;
			// Return trip calculated...
		}

		return cost;
	};


	const calculateTotalCosts = (
		deliveryCosts: number, 
		containerType: ContainerTypes | null, 
		numberOfContainers: number
	) => {
		let total = deliveryCosts;

		if (containerType) {
			switch(containerType) {
				case Containers.size20StandardUsed:
					total += (twenty_foot_used * numberOfContainers);
					break;
				case Containers.size20StandardNew:
					total += (twenty_foot_new * numberOfContainers);
					break;
				case Containers.size40HighUsed:
					total += (fourty_foot_used * numberOfContainers);
					break;
				case Containers.size40HighNew:
					total += (fourty_foot_new * numberOfContainers);
					break;
				default:
					break;
			}
		}

		return total;
	};


	const formatStringMoneyWithCommas = (money: number) => {
		let moneyFormatted = '0.00';
		
		if (money !== undefined && money !== null && typeof money === 'number' && money > 0) {
			moneyFormatted = `${money.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}.00`;
		}
		
		return moneyFormatted;
	};


	const getStepLabelByType = (label: string, step: number) => {
		let newLabel = label;

		switch(step) {
			case 0:
				if (selectedTileKey) {
					newLabel = `Containers: (${containerQuantity}) ${getContainerNameByType(selectedTileKey)}`;
				}
				break;
			case 1:
				if (selectedCoordinates && destinationAddress) {
					newLabel = `Deliver to: ${destinationAddress}`;
				}
				break;
			case 2:
				if (deliveryTime) {
					newLabel = `Deliver on: ${deliveryTime?.format('ddd MMM Do, YYYY - h:mma')}`;
				}
				break;
			case 3:
				if (customerEmail !== '' && customerName !== '') {
					newLabel = `Send to: ${customerName}, ${customerEmail}`;
				}
				break;
			default:
				break;
		}

		return newLabel;
	};


	const calculateHoursAndMinutesOfTime = () => {
		const hoursAndMins: iHoursAndMinutes = {
			hours: 0, mins: 0 
		};

		if (timeToTravel > 0) {
			const minutesOfTravelRounded = Math.ceil(timeToTravel / 60);

			if (minutesOfTravelRounded > 60) {
				hoursAndMins.mins = minutesOfTravelRounded % 60;
				hoursAndMins.hours = Math.trunc(minutesOfTravelRounded / 60);
			
			} else {
				hoursAndMins.mins = minutesOfTravelRounded;
			}
		}

		return hoursAndMins;
	};
	
	
	const getDeliveryTimePretty = () => {
		let viewableTime = '0 munutes';

		const travelTime = calculateHoursAndMinutesOfTime();

		if (travelTime.hours > 0) {
			viewableTime = `${travelTime.hours}hrs, ${travelTime.mins} mins`;
		
		} else {
			viewableTime = `${travelTime.mins.toFixed(2)} minutes`;
		}

		return viewableTime;
	};


	const getNextButtonDisabledState = () => {
		let isDisabled = false;

		if (activeStep === 0 && selectedTileKey === null || containerQuantity === 0) {
			isDisabled = true;
		} else if (activeStep === 1 && selectedCoordinates === mapCenterConfig.headquarters) {
			isDisabled = true;

		} else if (activeStep === 2 && deliveryTime === null) {
			isDisabled = true;
		}

		return isDisabled;
	};


	const getStepContent = (step: number) => {
		switch(step) {
			case 0:
				return renderChooseContainerStep();
			case 1:
				return renderLocationStep();
			case 2:
				return renderDeliveryTimeStep();
			case 3:
				return renderOrderReviewStep();

			default:
				return 'Unknown step';
		}
	};


	const getContainerNameByType = (key: ContainerTypes | null) => {
		let name = 'none';
		
		if (key && key !== null) {
			switch (key) {
				case Containers.size20StandardUsed:
					name = `Size 20' Standard [Used]`;
					break;
				case Containers.size20StandardNew:
					name = `Size 20' Standard [New]`;
					break;
				case Containers.size40HighUsed:
					name = `Size 40' High Cube [Used]`;
					break;
				case Containers.size40HighNew:
					name = `Size 40' High Cube [New]`;
					break;
			
				default:
					break;
			}
		}

		return name;
	};
	
	
	const getContainerImageByType = (key :ContainerTypes | null) => {
		let img = null;
		
		if (key && key !== null) {
			switch (key) {
				case Containers.size20StandardUsed:
					img = (
						<img 
							src={blueShortImage}
							alt={getContainerNameByType(key)}
							className={classes.finalReviewContainerImage} />
					);
					break;
				case Containers.size20StandardNew:
					img = (
						<img 
							src={blueHighImage}
							alt={getContainerNameByType(key)}
							className={classes.finalReviewContainerImage} />
					);
					break;
					
				case Containers.size40HighUsed:
					img = (
						<img 
							src={redShortImage}
							alt={getContainerNameByType(key)}
							className={classes.finalReviewContainerImage} />
					);
					break;
				case Containers.size40HighNew:
					img = (
						<img 
							src={redHighImage}
							alt={getContainerNameByType(key)}
							className={classes.finalReviewContainerImage} />
					);
					break;
			
				default:
					break;
			}
		}

		return img;
	};


	const calculateTimeToTravel = () => {
		const DistanceMatrix = GoogleRoutesLibrary?.DistanceMatrixService;

		if (DistanceMatrix) {
			// Calculating...
			const matrix = new DistanceMatrix();

			matrix.getDistanceMatrix({
				travelMode: google.maps.TravelMode.DRIVING,
				destinations: [`${selectedCoordinates.lat},${selectedCoordinates.lng}`],
				origins: [`${mapCenterConfig.headquarters.lat},${mapCenterConfig.headquarters.lng}`],
				drivingOptions: {
					departureTime: new Date(),
					trafficModel: GoogleRoutesLibrary.TrafficModel.PESSIMISTIC,
				},
			}, (response, status) => {
				if (response) {
					if (response.destinationAddresses?.length > 0) {
						setDestinationAddress(
							response.destinationAddresses[0]
						);
					}

					if (status === GoogleRoutesLibrary.DistanceMatrixStatus.OK) {
						const row = response.rows[0];
						if (row?.elements?.length > 0) {
							const element = row.elements[0];
	
							if (element) {
								setTimeToTravel(element?.duration?.value);
							}
						}
					}
				}
			});
		}

		// Always turn off
		setCalculatingCosts(false);
	};


	const renderChooseContainerStep = () => {
		return (
			<div className={classes.chooseContainer} ref={chooseContainerStepRef}>
				<div className={classes.selectedContainer}>
					<Typography 
						variant='h6' 
						className={classes.stepTitle}
						style={{
							flex: 3,
							textAlign: 'left',
						}}>

						<span style={{
							textDecoration: 'underline' 
						}}>Container Selected</span>:&nbsp;
						<span style={{
							fontWeight: 'bold',
							color: theme.palette.primary.main,
						}}>
							{`${getContainerNameByType(selectedTileKey)}`}
						</span>
					</Typography>
				</div>

				<Typography variant='subtitle1' fontStyle={'italic'}>
					Please select the containers you would like to order
				</Typography>
				
				<SelectableTileGroup<ContainerTypes> 
					selectedTile={selectedTileKey}
					onChange={handleContainerSelectionOnChange}>

					<SelectableTile key={Containers.size20StandardUsed}>
						<>
							{ getContainerImageByType(Containers.size20StandardUsed) }

							<Typography variant='h6'>
								{ getContainerNameByType(Containers.size20StandardUsed) }
							</Typography>
						</>
					</SelectableTile>
					
					<SelectableTile key={Containers.size20StandardNew}>
						<>
							{ getContainerImageByType(Containers.size20StandardNew) }

							<Typography variant='h6'>
								{ getContainerNameByType(Containers.size20StandardNew) }
							</Typography>
						</>
					</SelectableTile>

					<SelectableTile isDisabled key={Containers.size40HighUsed}>
						<>
							<div className={classes.containerTile}>

								{ getContainerImageByType(Containers.size40HighUsed) }
								<div className={classes.containerTileOutOfStockBackground} />

								<div className={classes.containerTileBanner}>
									<Typography variant='h6' className={classes.outOfStockLabel}>
										Out of Stock
									</Typography>
								</div>
							</div>

							<Typography variant='h6'>
								{ getContainerNameByType(Containers.size40HighUsed) }
							</Typography>
						</>
					</SelectableTile>
					
					<SelectableTile key={Containers.size40HighNew}>
						<>
							{ getContainerImageByType(Containers.size40HighNew) }

							<Typography variant='h6'>
								{ getContainerNameByType(Containers.size40HighNew) }
							</Typography>
						</>
					</SelectableTile>
				</SelectableTileGroup>

				<Typography 
					variant='subtitle2' 
					fontStyle={'italic'} 
					style={{
						marginTop: 20,
						fontSize: '0.575rem',
					}}>
					<sup>*</sup>These images (above) of shipping containers, are for illustration purposes 
					only and may not be an exact representation of the product. Colours and conditions may vary.
				</Typography>
				
				<Typography 
					variant='subtitle2' 
					fontStyle={'italic'} 
					style={{
						marginTop: 20,
						fontSize: '0.575rem',
					}}>
					<sup>*</sup>All containers are leak free and tight seal and are still used for trucking / cargo.
				</Typography>

				<div className={classes.containerCountWrapper}>
					<Typography variant='h6'>
						<span style={{
							textDecoration: 'underline' 
						}}>
							Number of containers
						</span>:&nbsp;&nbsp;&nbsp;&nbsp;
					</Typography>

					<Input
						type={'number'}
						label={'How Many?'}
						value={containerQuantity}
						fieldName={'containerQuantity'}
						className={classes.containerQuantity}
						onChange={handleContainerQuantityChange}
						helperText={'How many containers would you like?'} />
				</div>
			</div>
		);
	};


	const renderLocationStep = () => {
		return (
			<div className={classes.locationStepContainer} ref={locationStepRef}>
				<Typography variant='subtitle1' fontStyle={'italic'}>
					Please enter the exact address and destination for your shipping container
				</Typography>

				<AutoCompletePlaces 
					value={destinationAddress || undefined}
					onPlaceSelect={(place: google.maps.places.PlaceResult | null) => {
						if (place?.geometry) {
							const {
								geometry,
								formatted_address, 
							} = place;

							const lat = geometry?.location?.lat();
							const lng = geometry?.location?.lng();

							if (lat && lng) {
								setSelectedCoordinates({
									lat,
									lng,
								});

								if (formatted_address) {
									setDestinationAddress(formatted_address);
								}

							} else {
								console.warn('No coordinates given for this location! Please try again...');
							}
						}
					}} />

				<Map
					style={{
						width: isMobile ? '100%' : '50vw',
						height: isMobile ? '60vh' : '50vh',
					}}
					defaultCenter={{
						lat: mapCenterConfig.headquarters.lat,
						lng: mapCenterConfig.headquarters.lng,
					}}
					defaultZoom={14}
					center={coordinates}
					disableDefaultUI={true}
					gestureHandling={'greedy'}
					onClick={handleMapOnClick}
					mapId={'seacanman-map-api'}
					onCameraChanged={(event: MapCameraChangedEvent) => {
						setCoordinates({
							lat: event.detail.center.lat,
							lng: event.detail.center.lng,
						})
					}}
				>
					
				</Map>
			</div>
		);
	};


	const renderDeliveryTimeStep = () => {
		return (
			<div className={classes.deliveryTimeContainer} ref={deliveryTimeStepRef}>
				<Typography 
					variant='h6'
					fontWeight={'bold'}
					style={{
						marginTop: 20,
						marginBottom: 40,
					}}>
					Date and Time Selected:&nbsp;
					<span 
						style={{
							fontWeight: 'bold',
							color: theme.palette.primary.main,
						}}>
						
						{ deliveryTime?.format('ddd MMM Do, YYYY - h:mma') }
					</span>
				</Typography>
				
				<Typography variant='subtitle1'>
					Please select a Date and a Time that you would like to have your container delivered.
				</Typography>

				<DateTimePicker 
					autoFocus={false}
					disablePast={true}
					closeOnSelect={true}
					value={deliveryTime}
					open={isDatePickerOpen}
					formatDensity={'spacious'}
					label='Choose a day and time for delivery'
					onChange={handleDeliveryTimeOnChange}
					onOpen={() => {
						setIsDatePickerOpen(true);
					}}
					onClose={() => {
						setIsDatePickerOpen(false);
					}}
					slotProps={{
						textField: {
							onClick: () => setIsDatePickerOpen(true)
						},
					}}
					 />
			</div>
		);
	};

	const renderOrderReviewStep = () => {
		return (
			<div className={classes.orderReviewContainer} ref={orderReviewStepRef}>
				<Typography variant='h6' className={classes.orderTitle}>
					Who are you?
				</Typography>

				<div className={classes.orderReviewStepCustomerInfo}>
					<Input
						type={'text'}
						value={customerName}
						fieldName={CustomerFormKeys.name}
						label={'* First and Last Name'}
						className={classes.customerName} 
						onChange={handleCustomerNameChange}
						helperText={'Please enter your name'}
						validation={{
							required: '*Required',
						}}
					/>
						
					<Input
						type={'text'}
						label={'* Your email'}
						value={customerEmail}
						fieldName={CustomerFormKeys.email}
						className={classes.customerEmail} 
						onChange={handleCustomerEmailChange}
						helperText={'Please enter your email address'}
						validation={{
							required: '*Required',
							pattern: {
								value: validEmailRegex,
								message: 'Must be a valid email address',
							}
						}} />
						
					<Input
						type={'text'}
						value={customerPhone}
						label={'* Your phone number'}
						className={classes.customerEmail} 
						fieldName={CustomerFormKeys.phone}
						onChange={handleCustomerPhoneChange}
						helperText={'Please enter your phone number'}
						validation={{
							required: '*Required',
						}} />
				</div>
				
				<Typography 
					variant='h6' 
					className={classes.orderTitle} 
					style={{
						marginBottom: 20 
					}}>
					Review Your Order
				</Typography>

				<Grid container>
					<Grid item xs={12} sm={4}>
						{ getContainerImageByType(selectedTileKey) }
					</Grid>

					<Grid item xs={12} sm={8}>
						<ul className={classes.orderList}>
							<li className={classes.orderListItem}>
								Number of Containers:&nbsp;&nbsp;&nbsp;
								<span className={classes.orderItem}>
									{ containerQuantity }
								</span>
							</li>
							<li className={classes.orderListItem}>
								Container:&nbsp;&nbsp;&nbsp;
								<span className={classes.orderItem}>
									{ getContainerNameByType(selectedTileKey) }
								</span>
							</li>
							<li className={classes.orderListItem}>
								Date & Time:&nbsp;&nbsp;&nbsp;
								<span className={classes.orderItem}>
									{ deliveryTime?.format('ddd MMM Do, YYYY - h:mma') }
								</span>
							</li>
							<li className={classes.orderListItem}>
								Location:&nbsp;&nbsp;&nbsp;
								<span className={classes.orderItem}>
									{ destinationAddress }
								</span>
							</li>
						</ul>
					</Grid>
				</Grid>

				<Typography 
					variant='h5' 
					fontWeight={'bold'} 
					style={{
						marginTop: 20,
						marginBottom: 40,
					}}>

					Total Cost:&nbsp;
					<span className={classes.totalCost}>
						${ 
							formatStringMoneyWithCommas(
								calculateTotalCosts(
									calculateDeliveryCosts(selectedTileKey, containerQuantity),
									selectedTileKey,
									containerQuantity
								)
							) 
						}
					</span>
					&nbsp;+ GST/HST
				</Typography>
			</div>
		);
	};


	return (
		<Form<iCustomerForm> 
			key={'customer_form'}
			formClassOverride={classes.container}
			onSubmit={(data: iCustomerForm) => {
				completeOrder(data);
			}}>

			<Stepper 
				activeStep={activeStep} 
				orientation={'vertical'}
				className={classes.stepper}>

				{
					steps.map((label, index) => {
						return (
							<Step key={label}>
								<StepLabel 
									StepIconProps={{
										className: classes.stepIcon 
									}}>

									<Typography variant='h5' color={theme.palette.text.primary}>
										{ getStepLabelByType(label, index) }
									</Typography>
								</StepLabel>

								<StepContent 
									style={{
										paddingLeft: '7.5%', 
										paddingRight: '7.5%',
									}}>

									{ getStepContent(index) }

									<div className={classes.actionButtons}>
										{
											activeStep > 0 && (
												<Button
													size={ButtonSizes.large}
													disabled={activeStep === 0}
													onClick={handlePreviousStepButtonClick}
													style={{
														marginRight: 20,
													}}>
													
													Back
												</Button>
											)
										}

										{
											(activeStep < (steps.length - 1)) && 
												<Button
													size={ButtonSizes.large}
													color={ButtonColors.primary}
													variant={ButtonVariants.contained}
													onClick={handleAdvanceButtonOnClick}
													disabled={getNextButtonDisabledState()}>
													
													{  'Next' }
												</Button>
										}
										{
											(activeStep >= (steps.length - 1)) && (
												<Button 
													type={'submit'}
													color={ButtonColors.success}
													// onClick={handleOrderOnClick}
													variant={ButtonVariants.contained}
													// disabled={checkOrderButtonDisabledState()}
												>

													{
														isSendingOrder ? 
															(
																<CircularProgress 
																	size={30} 
																	color='info' 
																	style={{
																		margin: '0 25px' 
																	}} />
															) : 
															'Complete Order'
													}
												</Button>
											)
										}
									</div>

								</StepContent>
							</Step>
						);
					})
				}
			</Stepper>
		</Form>
	);
};


export default withStyles(BuyContainerStepper, Styles);
