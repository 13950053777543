/*
	~ Selectable Tile Component
*/

import includeClasses from 'classnames';

import { useState } from 'react';
import { Theme } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { CSSObject } from '@emotion/react';
import { hasChildren } from '@interfaces/React';
import { getClassesType } from '@interfaces/tssReact';


const Styles = (theme: Theme) => ({
	container: {
		padding: 20,
		display: 'flex',
		cursor: 'pointer',
		alignItems: 'center',
		flexDirection: 'column',
		justifyContent: 'center',
		border: `4px solid rgba(0,0,0,0)`,
		transition: `border ${theme.transitions.duration.short} ${theme.transitions.easing.easeIn}`,
	} as CSSObject,
	isSelected: {
		border: `4px solid ${theme.palette.primary.light}`,
	} as CSSObject,
});

export type stylesType = ReturnType<typeof Styles>;


export interface iSelectableTileProps extends getClassesType<stylesType>, hasChildren {
	key?: string,
	isSelected?: boolean,
	isDisabled?: boolean,
	onChange?: (isSelected: boolean, key?: string) => void,
}

const SelectableTile = (props: iSelectableTileProps) => {
	const {
		key,
		onChange,
		children,
		isSelected,
		isDisabled = false,
	} = props;

	const classes = withStyles.getClasses<stylesType>(props);
	const [isSelectedInternally, setIsSelected] = useState(false);

	const handleSelectableTileOnClick = () => {
		if (!isDisabled) {
			// If the isSelected prop is passed, use that over the local state
			const isSelectedParse = isSelected === undefined ? isSelectedInternally : isSelected;
			
			setIsSelected(!isSelectedParse);
			
			if (onChange) {
				onChange(!isSelectedParse, key);
			}
		}
	};

	return (
		<div 
			onClick={handleSelectableTileOnClick}
			className={
				includeClasses({
					[classes.container]: true,
					[classes.isSelected]: isSelected === undefined ? isSelectedInternally : isSelected,
				})
			}>

			{ children }
		</div>
	);
};


export default withStyles(SelectableTile, Styles);