/*
	~ Selectable Tile Group Component
*/

import {
	Children,
	useState,
	ReactElement,
	useEffect,
	cloneElement,
} from 'react';

import { withStyles } from 'tss-react/mui';
import { CSSObject } from '@emotion/react';
import { Theme } from '@mui/system/createTheme';
// import { useNavigate } from 'react-router-dom';
import { getClassesType } from '@interfaces/tssReact';
import { iSelectableTileProps } from '@atoms/_selectableTile';


const Styles = (theme: Theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-evenly',

		[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
			gap: 12,
			flexDirection: 'column',
		},
	} as CSSObject,
});

export type stylesType = ReturnType<typeof Styles>;


export interface TileState { [key: string]: boolean }

interface iSelectableTileGroupProps<keyTypes> extends getClassesType<stylesType> {
	selectedTile: keyTypes | null,
	onChange?: (selectedTile: keyTypes) => void,
	children?: ReactElement<iSelectableTileProps>[],
}

const SelectableTileGroup = <keyTypes,>(props: iSelectableTileGroupProps<keyTypes>) => {
	const {
		onChange, 
		children,
		selectedTile,
	} = props;

	const [tiles, setTiles] = useState({} as TileState);
	const classes = withStyles.getClasses<stylesType>(props);

	useEffect(() => {
		// Setup tiles state
		setTiles(mapChildrenToTileState());

	}, []);

	const handleTileOnChange = (key: keyTypes) => () => {
		if (key &&  typeof key === 'string') {
			setTiles((oldTiles: TileState) => {
				const tileKeys = Object.keys(oldTiles);
				const newTileState: TileState = {};

				if (tileKeys?.length > 0) {
					for (const tileKey of tileKeys) {
						if (tileKey === key) {
							if (oldTiles[tileKey] !== true) {
								newTileState[tileKey] = true;
							} else {
								newTileState[tileKey] = false;
							}

						} else {
							newTileState[tileKey] = false;
						}
					}
				}

				return {
					...newTileState,
				}
			});

			if (onChange) {
				onChange(key);
			}
		}
	};

	const mapChildrenToTileState = () => {
		const newTiles: TileState = {};

		if (children) {
			Children.forEach(children, (child: ReactElement<iSelectableTileProps>) => {
				if (child?.key) {
					const {
						key,
						props,
					} = child;

					newTiles[key] = props.isSelected !== undefined ? props.isSelected : false;
				
				} else {
					console.warn(
						'Selectable Tile Group children should all have a key property in order to function properly'
					);
				}
			});
		}

		return newTiles;
	};

	const mapControlledChildTiles = () => {
		let newTiles: ReactElement<iSelectableTileProps>[] = [];

		if (children) {
			newTiles = Children.map(children, (child: ReactElement<iSelectableTileProps>) => {
				let isSelected = false;

				if (child?.key) {
					const { key } = child;
					isSelected = selectedTile === key;

					return cloneElement(child, {
						isSelected,
						onChange: handleTileOnChange(key as keyTypes),
					});
				}
			});
		}

		return newTiles;
	};

	return (
		<div className={classes.container}>
			{ mapControlledChildTiles() }
		</div>
	);
};


export default withStyles(SelectableTileGroup, Styles);