/**
 * 
 * 	~ Container Interfaces & Types
 * 
 */

export enum Containers {
	size20StandardUsed = 'size20StandardUsed',
	size20StandardNew = 'size20StandardNew',
	size40HighUsed = 'size40HighUsed',
	size40HighNew = 'size40HighNew',
}

export type ContainerTypes = `${Containers}`;